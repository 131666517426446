import { useEffect, useRef, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { styled } from '@mui/material/styles';
// @mui
import { Alert, Box, IconButton } from '@mui/material';

import { PATH_APP } from 'src/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';

import axiosInstance from 'src/utils/axios';

import Iconify from 'src/components/iconify';

//
import Main from './Main';
import Header from './header';
import './christmasStyle.css';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';
// hooks
import useResponsive from '../../hooks/useResponsive';

// ----------------------------------------------------------------------
const Toggler = styled(Box)(({ theme, alertheight }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  zIndex: theme.zIndex.modal,
  padding: theme.spacing(1.5),
  color: theme.palette.grey[800],
  backgroundColor: theme.palette.warning.main,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderBottomLeftRadius: theme.shape.borderRadius,
  height: alertheight,
  fontSize: 30,
}));

const Wrapper = styled(Box)(({ theme, open }) => ({
  right: 0,
  top: '5%',
  display: 'flex',
  position: 'fixed',
  alignItems: 'center',
  zIndex: theme.zIndex.modal,
}));

const CustomAlert = styled(Alert)(({ theme, open }) => ({
  marginLeft: theme.spacing(-1),
  marginRight: theme.spacing(-1),
  '&.MuiAlert-root': {
    minHeight: 60, // Example height
  },
}));

const TogglerWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export default function DashboardLayout() {
  const isDesktop = useResponsive('up', 'lg');
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const openToggler = useBoolean(true);
  const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;
  const [userTrackingMissing, setUserTrackingMissing] = useState(false);

  const [alertHeight, setAlertHeight] = useState(60);
  const alertRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (alertRef.current) {
      setAlertHeight(alertRef.current.clientHeight);
    }
  }, [openToggler.value]);

  const checkMissingTracking = async () => {
    try {
      const response = await axiosInstance.get('/tracking/get-missing-compilation');
      const { data } = response;
      setUserTrackingMissing(data?.missing || false);
    } catch (error) {
      setUserTrackingMissing(false);
    }
  };

  useEffect(() => {
    checkMissingTracking();
  }, []);

  return (
    <>
      <Header onOpenNav={handleOpen} />

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        {isDesktop ? <NavMini /> : renderNavVertical}

        <Main>
          <Outlet />
        </Main>

        {userTrackingMissing && (
          <Wrapper open={openToggler.value}>
            <TogglerWrapper>
              <Toggler
                alertheight={alertHeight}
                className="customizer-toggler"
                onClick={openToggler.onToggle}
              >
                <Iconify icon="subway:missing" fontSize={30} />
              </Toggler>
              <CSSTransition
                in={openToggler.value}
                open={openToggler.value}
                timeout={0}
                classNames={{
                  enterDone: 'enter-done',
                  exit: 'exit',
                }}
                unmountOnExit
              >
                <CustomAlert
                  icon={false}
                  variant="filled"
                  severity="warning"
                  ref={alertRef}
                  open={openToggler.value}
                  action={
                    <IconButton
                      color="inherit"
                      size="small"
                      onClick={() => {
                        openToggler.onToggle();
                        navigate(PATH_APP.tracciamento.home);
                      }}
                    >
                      <Iconify icon="fluent-mdl2:navigate-forward" fontSize={30} />
                    </IconButton>
                  }
                >
                  Caro Utente, sono stati riscontrati dei dati mancanti nel tuo pannello di
                  &quot;Rendicontazione Attività&quot;. Ti invitiamo a controllare ed a compilare
                  correttamente questa sezione al più presto.
                </CustomAlert>
              </CSSTransition>
            </TogglerWrapper>
          </Wrapper>
        )}
      </Box>
    </>
  );
}
