import { m } from 'framer-motion';
import PropTypes from 'prop-types';

// @mui
import { Container, Typography } from '@mui/material';

//
import { useAuthContext } from './useAuthContext';
// assets
import { ForbiddenIllustration } from '../assets/illustrations';
// components
import { MotionContainer, varBounce } from '../components/animate';

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
  hasContent: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string),
  not_roles: PropTypes.arrayOf(PropTypes.string),
};

export default function RoleBasedGuard({ hasContent, roles,not_roles , children }) {
  // Logic here to get current user role
  const { user } = useAuthContext();


  // const currentRole = 'user';
  const currentRole = user?.tags; // admin;

  if (typeof roles !== 'undefined' && (!roles.some(item => currentRole.includes(item)))) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Permesso negato
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            Non hai i permessi per accedere a questa sezione
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
      </Container>
    ) : null;
  }

  if (typeof not_roles !== 'undefined' && (not_roles.some(item => currentRole.includes(item)))) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Permesso negato
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            Non hai i permessi per accedere a questa sezione
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
      </Container>
    ) : null;
  }

  return <> {children} </>;
}
