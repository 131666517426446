import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';

// utils
import axios from '../utils/axios';
//


// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const response = await axios.get('/my-account');
      const { user } = response.data;
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: true,
          user,
        },
      });
    } catch (error) {
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const two_factor = useCallback(async (username, code) => {
    const response = await axios.post('/two_factor', {
      username,
      code,
    });

    const { user } = response.data;

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  }, []);

  // LOGIN
  const login = useCallback(async (username, password) => {
    const response = await axios.post('/login', {
      username,
      password,
    });

    const { user } = response.data;
  }, []);

  // REGISTER
  const register = useCallback(async (username, password) => {
    const response = await axios.post('/register', {
      username,
      password,
    });

    return response;
  }, []);

  // VERIFY
  const verify = useCallback(async (code, username) => {
    const response = await axios.post('/verify', {
      username,
      code,
    });

    return response;
  }, []);

  // VERIFY FORGOT
  const verify_forgot = useCallback(async (code, username) => {
    const response = await axios.post('/verify-forgot', {
      username,
      code,
    });

    return response;
  }, []);

  // FORGOT PASSWORD
  const reset_password = useCallback(async (username) => {
    const response = await axios.post('/reset-password', {
      username,
    });

    return response;
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    await axios.post('/logout');

    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      login,
      two_factor,
      register,
      logout,
      verify,
      reset_password,
      verify_forgot,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      two_factor,
      logout,
      register,
      verify,
      reset_password,
      verify_forgot,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
