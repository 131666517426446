// scroll bar
import 'simplebar-react/dist/simplebar.min.css';
// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { LicenseInfo } from '@mui/x-license-pro';
import { Stack, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider as LocalizationProviderPro } from '@mui/x-date-pickers-pro';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
import Scrollbar from './components/scrollbar';
import { AuthProvider } from './auth/JwtContext';
// components
import SnackbarProvider from './components/snackbar';
import ScrollToTop from './components/scroll-to-top';
import { REACT_APP_LICENSE_KEY } from './config-global';
import { MotionLazyContainer } from './components/animate';
import { MaintenanceIllustration } from './assets/illustrations';
import { ThemeSettings, SettingsProvider } from './components/settings';

// ----------------------------------------------------------------------

export default function App() {
  const MAINTENANCE = process.env.REACT_APP_MAINTENANCE || 'false';
  LicenseInfo.setLicenseKey(REACT_APP_LICENSE_KEY);

  return MAINTENANCE === 'false' ? (
    <AuthProvider>
      <LocalizationProviderPro dateAdapter={AdapterDateFns}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <HelmetProvider>
            <SettingsProvider>
              <BrowserRouter>
                <ScrollToTop />
                <MotionLazyContainer>
                  <ThemeProvider>
                    <ThemeSettings>
                      <SnackbarProvider>
                        <Scrollbar>
                          <Router />
                        </Scrollbar>
                      </SnackbarProvider>
                    </ThemeSettings>
                  </ThemeProvider>
                </MotionLazyContainer>
              </BrowserRouter>
            </SettingsProvider>
          </HelmetProvider>
        </LocalizationProvider>
      </LocalizationProviderPro>
    </AuthProvider>
  ) : (
    <Stack sx={{ alignItems: 'center' }}>
      <Typography variant="h3" sx={{ mb: 2 }}>
        Il sito è attualmente in manutenzione
      </Typography>

      <Typography sx={{ color: 'text.secondary' }}>
        Stiamo lavorando duramente per ripristinare il servizio!
      </Typography>

      <MaintenanceIllustration sx={{ my: 10, height: 240 }} />
    </Stack>
  );
}
