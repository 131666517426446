// utils
import filter from 'lodash/filter';
import toLower from 'lodash/toLower';
import includes from 'lodash/includes';
import { useCallback, useEffect, useState } from 'react';

// @mui
import { alpha } from '@mui/material/styles';
import { Typography, ListItemText, ListItemAvatar, MenuItem, TextField } from '@mui/material';

// components
import axios from '../../../utils/axios';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import { CustomAvatar } from '../../../components/custom-avatar';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 64;

export default function ContactsPopover() {
  const [openPopover, setOpenPopover] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const filteredContacts = filterContacts(contacts, inputValue);

  const getContacts = useCallback(async () => {
    try {
      const response = await axios.get('/get-contacts');
      const { data } = response;
      setContacts(data);
    } catch (error) {
      // Errore irrilevante
    }
  }, []);

  useEffect(() => {
    getContacts();
  }, [getContacts]);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
        sx={{
          width: 40,
          height: 40,
          ...(openPopover && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <Iconify icon="mdi:telephone" />
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 320, mx: 3 }}>
        <Typography variant="h6" sx={{ p: 1.5 }}>
          Interni telefonici <Typography component="span">({filteredContacts.length})</Typography>
        </Typography>

        <TextField
          value={inputValue}
          onChange={(event, value) => {
            setInputValue(event.target.value);
          }}
          size="small"
          fullWidth
          label="Cerca"
        />

        <Scrollbar sx={{ height: ITEM_HEIGHT * 6 }}>
          {filteredContacts.map((contact) => {
            const nameParts = contact.name.replace(' ', '.').toLowerCase();

            return (
              <MenuItem key={contact._id} sx={{ height: ITEM_HEIGHT }}>
                <ListItemAvatar>
                  <CustomAvatar name={nameParts} />
                </ListItemAvatar>

                <ListItemText
                  primary={contact.name}
                  secondary={contact.number}
                  primaryTypographyProps={{ typography: 'subtitle2', sx: { mb: 0.25 } }}
                  secondaryTypographyProps={{ typography: 'subtitle3' }}
                />
              </MenuItem>
            );
          })}
        </Scrollbar>
      </MenuPopover>
    </>
  );
}

const filterContacts = (data, value) => {
  if (value === '') {
    return data;
  }

  const intValue = parseInt(value, 10); // Parse the value to an integer
  const lowerValue = toLower(value); // Convert the value to lowercase

  return filter(data, (contact) => {
    const { name, number } = contact;
    const lowerName = toLower(name); // Convert the name to lowercase
    
    return includes(lowerName, lowerValue) || number === intValue;
  });
};
