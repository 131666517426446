import PropTypes from 'prop-types';
// form
import { FormProvider as Form } from 'react-hook-form';

// ----------------------------------------------------------------------

FormProvider.propTypes = {
  children: PropTypes.node,
  methods: PropTypes.object,
  onSubmit: PropTypes.func,
  sx: PropTypes.object,
};

export default function FormProvider({ children, onSubmit, methods, sx }) {
  return (
    <Form style={{ ...sx }} {...methods}>
      <form style={{ ...sx }} onSubmit={onSubmit}>
        {children}
      </form>
    </Form>
  );
}
