import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

// @mui
import {
  Box,
  Stack,
  List,
  Badge,
  Button,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListItemAvatar,
  ListItem,
  Tabs,
  Tab,
} from '@mui/material';

import axios from '../../../utils/axios';
import Iconify from '../../../components/iconify';
// utils
import { fToNow } from '../../../utils/formatTime';
import Scrollbar from '../../../components/scrollbar';
import { useSnackbar } from '../../../components/snackbar';
import MenuPopover from '../../../components/menu-popover';
// components
import { useAuthContext } from '../../../auth/useAuthContext';
import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const [openPopover, setOpenPopover] = useState(null);
  const [categories, setCategories] = useState(['all']); // Initialize with 'all' category
  const [selectedTab, setSelectedTab] = useState('all');
  const { user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const [notifications, setNotifications] = useState([]);

  const filteredNotifications = notifications.filter((notification) => {
    if (selectedTab === 'all') {
      return true; // Show all notifications when the 'all' tab is selected
    }
    return notification.category === selectedTab;
  });

  const getNotificationCounter = (cat) =>
    notifications.filter((notification) => {
      if (cat === 'all') {
        return !notification.seen_by.includes(user.username);
      }
      return notification.category === cat && !notification.seen_by.includes(user.username);
    }).length;
  const getNotifications = useCallback(async () => {
    try {
      const response = await axios.get('/get-notifications');
      const { data } = response;
      // Extract distinct categories using a Set
      const uniqueCategories = Array.from(
        new Set(data.map((notification) => notification.category))
      );

      // Include 'all' category in the list
      const categoriesArray = ['all', ...uniqueCategories];

      setCategories(categoriesArray); // Update categories state
      setNotifications(data);
    } catch (error) {
      enqueueSnackbar('Errore nel recuperare le notifiche', { variant: 'error' });
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    // Initial request
    getNotifications();

    // Schedule subsequent requests every minute
    const interval = setInterval(() => {
      getNotifications();
    }, 300000);

    // Clean up the interval when the component unmounts or the dependency array changes
    return () => {
      clearInterval(interval);
    };
  }, [getNotifications]);

  const totalUnRead = notifications.filter((item) => !item.seen_by.includes(user.username)).length;

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleMarkAllAsRead = async () => {
    try {
      await axios.get('/mark-all-notifications-as-read');
      getNotifications();
    } catch (error) {
      enqueueSnackbar(error.message || error, { variant: 'error' });
    }
  };

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 500, p: 0, mx: 3, height: '80vh' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifiche</Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Hai {totalUnRead} notifiche non lette
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Segna tutto come letto">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <Tabs
          value={selectedTab}
          onChange={(event, newValue) => setSelectedTab(newValue)}
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          {categories.map((category) => (
            <Tab
              key={`${category}-tab`}
              label={
                category === 'all' ? (
                  <Typography sx={{ pl: 1 }}>Tutte</Typography>
                ) : (
                  <Badge
                    sx={{ ml: 2 }}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    badgeContent={getNotificationCounter(category)}
                    color="error"
                  >
                    <Typography sx={{ pl: 1 }}>{category}</Typography>
                  </Badge>
                )
              }
              value={category}
            />
          ))}
        </Tabs>

        <Scrollbar sx={{ height: '60vh' }}>
          <List disablePadding>
            {filteredNotifications.map((notification, index) => (
              <NotificationItem
                key={`${notification._id}-${index}`}
                notification={notification}
                username={user.username}
                refreshAll={getNotifications}
                closeAll={handleClosePopover}
              />
            ))}
          </List>
        </Scrollbar>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    _id: PropTypes.string,
    avatar: PropTypes.node,
    type: PropTypes.string,
    redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    download_url: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    title: PropTypes.string,
    seen_by: PropTypes.array,
    description: PropTypes.string,
    timestamp: PropTypes.string,
    open_id: PropTypes.any,
  }),
  username: PropTypes.string,
  refreshAll: PropTypes.func,
  closeAll: PropTypes.func,
};

function NotificationItem({ notification, username, refreshAll, closeAll }) {
  const { avatar, title } = renderContent(notification);
  const navigate = useNavigate();

  const markAsRead = async () => {
    if (!notification.seen_by.includes(username)) {
      try {
        await axios.post('/mark-single-notification', {
          id: notification._id,
        });
        refreshAll();
      } catch (error) {
        // PASS
      }
    }
  };

  return (
    <>
      <ListItem
        sx={{
          px: 2.5,
          mt: '1px',
          ...(!notification.seen_by.includes(username) && {
            bgcolor: 'action.selected',
          }),
        }}
        onClick={markAsRead}
      >
        <ListItemAvatar>
          <Iconify icon={avatar} width={32} />
        </ListItemAvatar>

        <ListItemText
          disableTypography
          primary={title}
          secondary={
            <Stack direction="row" sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled' }}>
              <Iconify icon="eva:clock-fill" width={16} sx={{ mr: 0.5 }} />
              <Typography variant="caption">
                {fToNow(`${notification.timestamp} UTC`) || notification.timestamp}
              </Typography>
            </Stack>
          }
        />
        {notification.redirect !== false && (
          <Button
            onClick={() => {
              if (notification.open_id) {
                if (notification.open_id) {
                  localStorage.setItem('open_id', notification.open_id);
                }
              }
              navigate(notification.redirect);
              markAsRead();
              closeAll();
            }}
          >
            Vai
          </Button>
        )}

        {notification.download_url !== false && (
          <Button
            onClick={() => {
              window.open(notification.download_url);
            }}
          >
            Scarica
          </Button>
        )}
      </ListItem>
      <Divider />
    </>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle1">
      {notification.title} :
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {notification.description}
      </Typography>
    </Typography>
  );

  if (notification.type === 'new_ticket') {
    return {
      avatar: 'mdi:support',
      title,
    };
  }
  if (notification.type === 'new_ticket_msg') {
    return {
      avatar: 'mdi:support',
      title,
    };
  }
  if (notification.type === 'new_ticket_status') {
    return {
      avatar: 'mdi:support',
      title,
    };
  }
  if (notification.type === 'new_reservation') {
    return {
      avatar: 'uil:meeting-board',
      title,
    };
  }
  if (notification.type === 'delete_reservation') {
    return {
      avatar: 'uil:meeting-board',
      title,
    };
  }
  if (notification.type === 'task_completed') {
    return {
      avatar: 'material-symbols:download',
      title,
    };
  }

  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
