// routes
import { PATH_APP } from '../../../routes/paths';
// components
import Iconify from '../../../components/iconify/Iconify';

// ----------------------------------------------------------------------

const icon = (name) => <Iconify icon={`${name}`} />;
const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      { title: 'Home', path: PATH_APP.home, icon: icon('mdi:home') },
      { title: 'Wiki', path: PATH_APP.wiki.home, icon: icon('mdi:wikipedia') },
      {
        title: 'Nad',
        path: PATH_APP.nad.home,
        icon: icon('gridicons:domains'),
        children: [
          { title: 'Home', path: PATH_APP.nad.home },
          { title: 'admin panel', path: PATH_APP.nad.admin_panel },
          { title: 'Backup Panel', path: PATH_APP.nad.backup_panel },
        ],
      },
      {
        title: 'Marketplace',
        path: PATH_APP.marketplace.home,
        icon: icon('mdi:marketplace'),
      },
      {
        title: 'Map',
        path: PATH_APP.map.home,
        icon: icon('carbon:cloud-monitoring'),
      },
      {
        title: 'Strumenti',
        path: '',
        icon: icon('fluent:window-dev-tools-24-filled'),
        children: [
          { title: 'Scaricamenti', path: PATH_APP.scaricamenti.home },
          { title: 'Crawling Requests', path: PATH_APP.crawling.home },
          {
            title: 'Dns',
            path: PATH_APP.dns.discovery,
            children: [
              { title: 'Discovery', path: PATH_APP.dns.discovery },
              { title: 'Summary', path: PATH_APP.dns.summary },
            ],
          },
          { title: 'Stream', path: PATH_APP.stream },
          { title: 'RawData', path: PATH_APP.rawdata.home },
          { title: 'Designs', path: PATH_APP.socials.home },
          { title: 'Gallery', path: PATH_APP.gallery },
          { title: 'Match', path: PATH_APP.similarity.clients },
          // { title: 'Convey G8', path: PATH_APP.convey_lens.clients },
        ],
      },
      {
        title: 'Gestione',
        path: '',
        icon: icon('ep:management'),
        children: [
          { title: 'Ticket', path: PATH_APP.ticket.home },
          { title: 'Supporto Analisti', path: PATH_APP.analystsupport },
          { title: 'Bacheca', path: PATH_APP.bacheca.home },
          { title: 'Help Desk', path: PATH_APP.help_desk.home },
        ],
      },
      {
        title: 'Amministrazione e personale',
        path: '',
        icon: icon('carbon:id-management'),
        children: [
          { title: 'Risorse Umane', path: PATH_APP.hr.home },
          { title: 'Connessioni', path: PATH_APP.calendario.home },
          { title: 'Assenze', path: PATH_APP.assenze.home },
          { title: 'Prenotazioni sale', path: PATH_APP.prenotazioni.home },
          { title: 'Rendicontazione', path: PATH_APP.tracciamento.home },
        ],
      },
      {
        title: 'Control Panel',
        path: PATH_APP.mngt_page,
        icon: icon('carbon:cloud-service-management'),
      },
    ],
  },
];

export default navConfig;
