import { it } from 'date-fns/locale';
import { format, getTime, formatDistanceToNow, differenceInCalendarDays } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  if (!date) {
    return '';
  }

  try {
    const parsedDate = new Date(`${date}`);
    if (Number.isNaN(parsedDate)) {
      // Handle the case when the date parameter is not a valid date string
      // You can throw an error or return a message to indicate the issue
      throw new Error('Invalid date string');
    }

    return formatDistanceToNow(parsedDate, {
      addSuffix: true,
      locale: it,
    });
  } catch (error) {
    return date;
  }
}

export function distanceInDays(date) {
  if (!date) {
    return '';
  }

  try {
    const parsedDate = new Date(`${date}`);
    if (Number.isNaN(parsedDate.getTime())) {
      // Handle the case when the date parameter is not a valid date string
      throw new Error('Invalid date string');
    }

    const today = new Date();
    const difference = differenceInCalendarDays(parsedDate, today);

    if (difference === 0) {
      return 'Oggi';
    }
    if (difference === 1) {
      return 'Domani';
    }
    if (difference === -1) {
      return 'Ieri';
    }
    if (difference > 1) {
      return `${Number(difference)} giorni`;
    }
    return `${Math.abs(difference)} giorni fa`;
  } catch (error) {
    return date;
  }
}
